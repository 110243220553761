import EndpointDetailTable from "components/ui/EndpointDetailTable";
import { useState, useEffect, useMemo, useCallback } from "react";
import { useParams } from "react-router-dom";
import usePageTitle from "system/hooks/use-page-title";
import ITitleData from "system/types/interfaces/ITitleData";
import IMachineDetails from "system/types/interfaces/IMachineDetails";
import ComplianceStatus from "components/ui/ComplianceStatus";
import EndpointSubjectStatusList from "components/ui/EndpointSubjectStatusList";
import LoadingPage from "./system/LoadingPage";
import { ComplianceStatusToString, getFactValue, toDate, toFormatedDate, toLocalISOString } from "system/libraries/utilities";
import ActionButtons from "components/ui/ActionButtons";
import useChartRange from "system/hooks/use-chart-range";
import AppErrorPage from "./system/AppErrorPage";
import usePageNavigation from "system/hooks/use-page-navigation";
import useApi from "system/hooks/use-api";
import useBreadcrumbs from "system/hooks/use-breadcrumbs";
import IAppBreadcrumb from "system/types/interfaces/IAppBreadcrumb";
import ExportToCsvButton from "components/ui/ExportToCsvButton";
import { descriptionByFactCode } from "system/libraries/constants";
import { useUrlSearch } from "system/hooks";
import TheMainChart from "components/ui/TheMainChart";
import { SubjectStatusSummary, SubjectStatusTime } from "system/types/wireTypes";

const factsToExport = [
  "COMPUTERNAME",
  "ADSITE",
  "OSARCH",
  "BIOSVER",
  "CONFIG",
  "MFR",
  "MODEL",
  "BOARDSER",
  "OSNAME",
  "OSVER",
  "REPO",
  "WINACCT",
];

const EndpointPage = () => {
  const api = useApi();
  const { machineId } = useParams();
  const { getEndpointPageUrl, navigateToEndpointReportHistoryPage } = usePageNavigation();
  const [refreshCount, setRefreshCount] = useState(0);
  const [machineData, setMachineData] = useState<IMachineDetails | null>(null);
  const [loadError, setLoadError] = useState<string | null>(null);
  const [chartData, setChartData] = useState<SubjectStatusTime[] | null>(null);

  const [titleData, setTitleData] = useState<ITitleData>({
    title: "Endpoint Information",
    subTitle: "",
  });

  usePageTitle(titleData);

  const urlSearch = useUrlSearch();
  const dates = useChartRange();

  const getSelectedDateString = useCallback((): string | null => {
    const paramDate = urlSearch.get("selectedDate");

    if (paramDate) {
      return paramDate;
    }

    if (dates?.length) {
      const resultDate = toLocalISOString(dates[dates.length - 1]);
      return resultDate;
    }

    return null;
  }, [urlSearch, dates]);

  const time = useMemo(() => getSelectedDateString(), [getSelectedDateString]);

  useBreadcrumbs(
    useMemo<IAppBreadcrumb[]>(() => {
      let result: IAppBreadcrumb[] = [];
      if (machineData?.machineName) {
        result.push({
          name: machineData.machineName,
          url: time ? getEndpointPageUrl(machineId, time) : "",
        });

        if (time) {
          result.push({
            title: "Time",
            name: toFormatedDate(time),
            url: `/?selectedDate=${time}`,
          });
        }
      }

      return result;
    }, [time, machineData, getEndpointPageUrl, machineId])
  );

  const loadMainChart = useCallback(() => {
    if (!dates) {
      setChartData(null);
      return;
    }

    api.summary
      .subjectStatus({
        breakoutOption: 0,
        filters: {
          adSite: null,
          computerName: null,
          configurationName: null,
          hardwareMfr: null,
          hardwareModel: null,
          subject: null,
        },
        graphBy: "subject",
        machine: machineId,
        referenceTimes: dates.map((x: Date) => x.toISOString()),
      })
      .then((data: SubjectStatusSummary | null) => {
        if (data?.isSuccess && data?.byBreakoutValue && data.byBreakoutValue.length > 0) {
          setChartData(data?.byBreakoutValue[0].byTime);
        } else {
          setChartData(null);
        }
      });
  }, [api.summary, dates, machineId]);

  useEffect(() => {
    if (!dates) {
      return;
    }

    let requestTime: Date | null = null;

    if (time && !isNaN(Date.parse(time))) {
      requestTime = new Date(time);
    }

    if (!!machineId && !isNaN(+machineId)) {
      api.machine
        .getMachineDetail(+machineId, requestTime, dates)
        .then((data) => {
          if (data.isSuccess) {
            setMachineData(data.value);
            const machineName = data.value?.machineName;
            loadMainChart();

            if (machineName) {
              setTitleData({
                title: machineName,
                subTitle: "Endpoint",
                navigateOnTitleClick: time ? getEndpointPageUrl(machineId) : "",
              });
            }
          } else {
            setLoadError(data.failMessage);
          }
        })
        .catch((error) => {
          setLoadError(error);
        });
    }

    return () => {
      setLoadError(null);
    };
  }, [machineId, dates, time, refreshCount, api, getEndpointPageUrl, loadMainChart]);

  const exportData = useMemo(
    () => [
      {
        text: "Asset data",
        fileName: `Aiden ${machineData?.machineName} Endpoint Asset Data ${toDate(machineData?.machineAtTime)}`,
        source: (factsToExport.map((x) => ({
          Property: descriptionByFactCode[x],
          Value: getFactValue(machineData?.machineFacts, x),
        }))).concat(
          [
            { 
              Property: "Last recorded",
              Value: toFormatedDate(machineData?.subjectStatuses?.length? machineData?.subjectStatuses[0]?.lastReportedJsTime : "")
            },
            { 
              Property: "Last recorded ISO8601",
              Value: machineData?.subjectStatuses?.length? machineData?.subjectStatuses[0]?.lastReportedJsTime : ""
            }
          ]),
      },
      {
        text: "Compliance data",
        fileName: `Aiden ${machineData?.machineName} Endpoint Compliance Data ${toDate(machineData?.machineAtTime)}`,
        source: machineData?.subjectStatuses?.map((x) => ({
          Subject: x.subject,
          Package: x.packageName,
          State: ComplianceStatusToString(x.complianceStatus),
          Justification: x.complianceJustification,
          AssessmentTime: toFormatedDate(x.referenceJsTime),
          AssessmentTimeISO8601: x.referenceJsTime,
          UseCase: x.packageType,
          Rule: x.ruleDescription,
          LastExitCode: x.packageExitCode,
          Status: x.applicabilityMessage,
        })),
      },
    ],
    [machineData]
  );

  const refreshHandler = () => {
    setMachineData(null);
    loadMainChart();
    setRefreshCount(refreshCount + 1);
  };

  const openReportHistoryHandler = () => {
    navigateToEndpointReportHistoryPage(machineId);
  };

  if (loadError) {
    return <AppErrorPage message={loadError} />;
  }

  if (!machineData) {
    return <LoadingPage />;
  }

  const complianceStatus = machineData.machineFacts?.find((x) => x.code === "COMPLIANCESTATUS");
  let complianceJustification = getFactValue(machineData.machineFacts, "COMPLIANCEJUSTIFICATION");
  if (!complianceJustification) {
    complianceJustification = "No reporting data";
  }

  return (
    <>
      <div style={{ paddingBottom: "10px" }}>
        <ComplianceStatus code={+(complianceStatus?.value ?? 0)} isUnknown={complianceStatus?.isUnknown ?? true} />
        <div style={{ paddingLeft: "10px", marginTop: "10px" }} className="ts-date-title">
          {complianceJustification}
        </div>
      </div>
      <ActionButtons
        timespanButton={true}
        onRefresh={refreshHandler}
        items={[
          {
            title: "Report history",
            action: openReportHistoryHandler,
            icon: "Clock",
          },
        ]}
      >
        <ExportToCsvButton items={exportData} />
      </ActionButtons>

      <div className="machine-detail-chart">
        <TheMainChart data={chartData} selectedGraphBy="subject" />

        <div className="summary-number" style={{ marginTop: "0px" }}>
          <div className="d-flex justify-content-between gap-3 align-items-center">
            <p className="mb-0">
              <span className="endpoint-head">Endpoint information</span>
            </p>
            <p className="d-flex flex-column flex-md-row gap-1 gap-md-2 align-items-md-center mb-0">
              <span className="lh-sm"> Selected date and time</span>
              <span className="number lh-sm">{toFormatedDate(machineData.machineAtTime.toString())}</span>
            </p>
          </div>
        </div>
        <EndpointDetailTable machineDetails={machineData} />
      </div>
      <div className="machine-table scroll" style={{ maxHeight: "70vh" }}>
        <EndpointSubjectStatusList
          subjectStatuses={machineData.subjectStatuses}
          time={machineData.machineAtTime.toString()}
          machineId={machineId}
          defaultSort={{ sort: "complianceStatus", sortDesc: false }}
        />
      </div>
    </>
  );
};

export default EndpointPage;
