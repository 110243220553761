import EndpointDetailElement from "./EndpointDetailElement";
import { toFormatedDate } from "system/libraries/utilities";
import IMachineDetails from "system/types/interfaces/IMachineDetails";

interface IProps {
  machineDetails: IMachineDetails
};

const EndpointDetailTable = ({ machineDetails } : IProps ) => {
  if (!machineDetails || !machineDetails.machineFacts) {
    return <></>;
  }

  return (
    <div className="row scroll">
      <div className="col-md-6">
        <table>
          <tbody>
            <EndpointDetailElement facts={machineDetails.machineFacts} code="COMPUTERNAME" />
            <EndpointDetailElement facts={machineDetails.machineFacts} code="ADSITE" />
            <EndpointDetailElement facts={machineDetails.machineFacts} code="OSARCH" />
            <EndpointDetailElement facts={machineDetails.machineFacts} code="BIOSVER" />
            <EndpointDetailElement facts={machineDetails.machineFacts} code="CONFIG" />
            <EndpointDetailElement facts={machineDetails.machineFacts} code="MFR" />
            <EndpointDetailElement facts={machineDetails.machineFacts} code="MODEL" />
          </tbody>
        </table>
      </div>
      <div className="col-md-6">
        <table>
          <tbody>
            <EndpointDetailElement facts={machineDetails.machineFacts} code="BOARDSER" />
            <EndpointDetailElement facts={machineDetails.machineFacts} code="OSNAME" />
            <EndpointDetailElement facts={machineDetails.machineFacts} code="OSVER" />
            <EndpointDetailElement facts={machineDetails.machineFacts} code="REPO" />
            <EndpointDetailElement facts={machineDetails.machineFacts} code="WINACCT" />
            <tr>
              <td><span className="endpoint-fact-code">Combo Id</span></td>
              <td><span className="endpoint-fact-value ms-2">: {machineDetails.comboId}</span></td>
            </tr>
            <tr>
              <td><span className="endpoint-fact-code">Last recorded</span></td>
              <td><span className="endpoint-fact-value ms-2">: {toFormatedDate(machineDetails?.subjectStatuses?.length? machineDetails?.subjectStatuses[0]?.lastReportedJsTime : "")}</span></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default EndpointDetailTable;