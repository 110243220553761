import { useCallback, useEffect, useMemo, useState } from "react";
import { descriptionByFactCode } from "system/libraries/constants";
import { useParams } from "react-router-dom";
import {
  ComplianceStatusToString,
  camelPad,
  getFactValue,
  isFactUnknown,
  normalizeString,
  statusToNumber,
  toDate,
  getSorter,
  toFormatedDate,
} from "system/libraries/utilities";
import { ListResponse, MachineAtTime } from "system/types/wireTypes";
import useMultiFilters from "system/hooks/use-multi-filters";
import usePageTitle from "system/hooks/use-page-title";
import MachineFact from "system/types/interfaces/MachineFact";
import ComplianceStatus from "components/ui/ComplianceStatus";
import usePageNavigation from "system/hooks/use-page-navigation";
import AppLink from "components/ui/AppLink";
import useApi from "system/hooks/use-api";
import LoadingPage from "./system/LoadingPage";
import useBreadcrumbs from "system/hooks/use-breadcrumbs";
import IAppBreadcrumb from "system/types/interfaces/IAppBreadcrumb";
import ActionButtons from "components/ui/ActionButtons";
import ExportToCsvButton from "components/ui/ExportToCsvButton";
import EndpointFilter from "components/ui/EndpointFilter";
import SortableTableHeader from "components/ui/SortableTableHeader";
import useSearchField from "system/hooks/use-search-field";

const getSorterFacts = (field: string, descending: boolean = false) => {
  return function (a: MachineAtTime, b: MachineAtTime): number {
    const valueA: any = getFactValue(a.facts as MachineFact[], field);
    const valueB: any = getFactValue(b.facts as MachineFact[], field);

    const leftValue = isNaN(valueA) ? valueA.toString().toLowerCase() : valueA;
    const rightValue = isNaN(valueB) ? valueB.toString().toLowerCase() : valueB;

    if (leftValue < rightValue || (leftValue === null && rightValue !== null)) {
      return descending ? 1 : -1;
    }

    if (leftValue > rightValue || (leftValue !== null && rightValue === null)) {
      return descending ? -1 : 1;
    }

    return 0;
  };
};

const EndpointsByStatusPage = () => {
  const api = useApi();
  const { navigateToHomePage, navigateToEndpointPage } = usePageNavigation();
  const { statusString, time } = useParams();
  const { sortDesc, sortField } = useSearchField();
  const complianceStatus = statusToNumber(statusString ?? "");
  const { machineFilterSet } = useMultiFilters();
  const [formattedTime] = useState<string>(toFormatedDate(time ?? ""));
  const [data, setData] = useState<MachineAtTime[] | null>(null);
  const [columnCodes] = useState<string[]>(["CONFIG", "COMPLIANCESTATUS", "COMPLIANCEJUSTIFICATION", "REPO", "ADSITE"]);

  const refreshHandler = () => {
    setData(null);
    loadMachineByStatusData();
  };

  const statusText = useMemo(() => {
    var result = camelPad(statusString ?? "");
    if (result === "Failed" || result === "Critical") {
      return "Critical action required";
    }

    return result;
  }, [statusString]);

  usePageTitle({
    title: statusText,
    subTitle: `Endpoints by state`,
  });

  useBreadcrumbs(
    useMemo<IAppBreadcrumb[]>(() => {
      let result: IAppBreadcrumb[] = [];

      if (time) {
        result.push({
          title: "Time",
          name: toFormatedDate(time),
          url: `/?selectedDate=${time}`,
        });
      }

      if (statusString && statusString !== "all") {
        result.push({
          title: "State",
          name: normalizeString(statusText),
        });
      }

      return result;
    }, [time, statusString, statusText])
  );

  const columnDescriptions = useMemo<string[]>(() => columnCodes.map((x) => descriptionByFactCode[x]), [columnCodes]);

  const getMachineByStatusData = useCallback(
    (noLimit?: boolean) => {
      if (!time || !machineFilterSet || complianceStatus === null) {
        return;
      }

      return api.list.machinesByStatus({
        complianceStatus,
        fromTime: new Date(time),
        filters: machineFilterSet,
        isUnknown: complianceStatus === 0,
        noLimit: noLimit === true,
      });
    },
    [api, time, complianceStatus, machineFilterSet]
  );

  const loadMachineByStatusData = useCallback(() => {
    getMachineByStatusData()?.then((result) => {
      if (!result?.isSuccess) {
        console.warn(result?.failMessage);
        return;
      }

      setData(result.items);
    });
  }, [getMachineByStatusData, setData]);

  useEffect(() => {
    loadMachineByStatusData();
  }, [loadMachineByStatusData]);

  const exportDataHandler = useCallback(async () => {
    const csvDataToExport = await getMachineByStatusData(true)
      ?.then((data: ListResponse<MachineAtTime> | null) => {
        return data?.isSuccess ? data.items : null;
      })
      .catch(() => null);

    return csvDataToExport?.map((item) => ({
      Endpoint: getFactValue(
        item.facts?.map((f) => f as MachineFact),
        "COMPUTERNAME"
      ),
      AssessmentTime: toFormatedDate(item.referenceJsTime!),
      AssessmentTimeISO8601: item.referenceJsTime,
      LastRecorded: toFormatedDate(item.lastReportedJsTime!),
      LastRecordedISO8601: item.lastReportedJsTime,
      Configuration: getFactValue(
        item.facts?.map((f) => f as MachineFact),
        "CONFIG"
      ),
      State: ComplianceStatusToString(
        getFactValue(
          item.facts?.map((f) => f as MachineFact),
          "COMPLIANCESTATUS"
        )
      ),
      Justification: getFactValue(
        item.facts?.map((f) => f as MachineFact),
        "COMPLIANCEJUSTIFICATION"
      ),
      Repository: getFactValue(
        item.facts?.map((f) => f as MachineFact),
        "REPO"
      ),
      ADSite: getFactValue(
        item.facts?.map((f) => f as MachineFact),
        "ADSITE"
      ),
    }));
  }, [getMachineByStatusData]);

  const sortedData = useMemo<typeof data>(() => {
    if (data === null) {
      return null;
    }

    switch (sortField) {
      case "time":
        return [...data].sort(getSorter("referenceJsTime", sortDesc));
      case "endpoint":
        return [...data].sort(getSorterFacts("COMPUTERNAME", sortDesc));
      case "Configuration":
        return [...data].sort(getSorterFacts("CONFIG", sortDesc));
      case "State":
        return [...data].sort(getSorterFacts("COMPLIANCESTATUS", sortDesc));
      case "Justification":
        return [...data].sort(getSorterFacts("COMPLIANCEJUSTIFICATION", sortDesc));
      case "Repository":
        return [...data].sort(getSorterFacts("REPO", sortDesc));
      case "Site":
        return [...data].sort(getSorterFacts("ADSITE", sortDesc));
      default:
        return [...data];
    }
  }, [data, sortField, sortDesc]);

  const exportData = useMemo(
    () => ({
      fileName: `Aiden ${statusText} Endpoints by State ${toDate(time)}`,
      source: exportDataHandler,
    }),
    [exportDataHandler, time, statusText]
  );

  if (!formattedTime || complianceStatus === null) {
    navigateToHomePage();
    return <></>;
  }

  return sortedData !== null ? (
    <>
      <ActionButtons onRefresh={refreshHandler}>
        <ExportToCsvButton items={[exportData]} />
      </ActionButtons>
      <EndpointFilter />
      <div className="machine-table scroll" style={{ maxHeight: "65vh" }}>
        <table className="table table-dialog sticky-table tb-endpoint">
          <thead className="sticky-top">
            <tr>
              <th className="fit align-middle" scope="col">
                <SortableTableHeader title="Endpoint" property="endpoint" className="ts-date-title truncated-header" />
              </th>
              <th className="fit align-middle" scope="col">
                <SortableTableHeader title="Assessment changed" property="time" className="ts-date-title truncated-header" />
              </th>
              <th className="fit align-middle" scope="col">
                <SortableTableHeader title="Last recorded" property="time" className="ts-date-title truncated-header" />
              </th>
              {columnDescriptions.map((description) => (
                <th key={description} className="align-middle">
                  <SortableTableHeader
                    title={description}
                    property={description}
                    style={description === "Justification" ? { width: "250px", display: "block" } : {}}
                    className="ts-date-title truncated-header"
                  />
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {sortedData.map((x, index) => (
              <tr key={index}>
                <td className="text-nowrap">
                  <AppLink onClick={() => navigateToEndpointPage(x.machineId, time)}>
                    {getFactValue(
                      x.facts?.map((f) => f as MachineFact),
                      "COMPUTERNAME"
                    )}
                  </AppLink>
                </td>
                <td className="text-nowrap text-bold">{toFormatedDate(x.referenceJsTime!)}</td>
                <td className="text-nowrap text-bold">{toFormatedDate(x.lastReportedJsTime!)}</td>
                {columnCodes.map((code) =>
                  code !== "COMPLIANCESTATUS" ? (
                    <td key={code}>
                      {getFactValue(
                        x.facts?.map((f) => f as MachineFact),
                        code
                      )}
                    </td>
                  ) : (
                    <td key={code}>
                      <ComplianceStatus
                        code={
                          +(
                            getFactValue(
                              x.facts?.map((f) => f as MachineFact),
                              code
                            ) ?? 0
                          )
                        }
                        isUnknown={isFactUnknown(
                          x.facts?.map((f) => f as MachineFact),
                          code
                        )}
                      />
                    </td>
                  )
                )}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  ) : (
    <LoadingPage />
  );
};

export default EndpointsByStatusPage;
