import AppLink from "components/ui/AppLink";
import SortableTableHeader from "components/ui/SortableTableHeader";
import {
  ComplianceStatusToString,
  decodeUriString,
  getSorter,
  normalizeString,
  statusToNumber,
  toDate,
  toFormatedDate,
} from "system/libraries/utilities";
import { ListResponse, SubjectStatusAtTime } from "system/types/wireTypes";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import useApi from "system/hooks/use-api";
import useMultiFilters from "system/hooks/use-multi-filters";
import usePageNavigation from "system/hooks/use-page-navigation";
import usePageTitle from "system/hooks/use-page-title";
import useSearchField from "system/hooks/use-search-field";
import ComplianceStatus from "components/ui/ComplianceStatus";
import LoadingPage from "./system/LoadingPage";
import useBreadcrumbs from "system/hooks/use-breadcrumbs";
import IAppBreadcrumb from "system/types/interfaces/IAppBreadcrumb";
import ITitleData from "system/types/interfaces/ITitleData";
import ActionButtons from "components/ui/ActionButtons";
import ExportToCsvButton from "components/ui/ExportToCsvButton";
import EndpointFilter from "components/ui/EndpointFilter";

const EndpointsByReasonPage = () => {
  const api = useApi();
  const { statusString, subject, time, message } = useParams();
  const { sortDesc, sortField } = useSearchField();
  const { navigateToEndpointPage, getSubjectByReasonPageUrl } = usePageNavigation();
  const [data, setData] = useState<SubjectStatusAtTime[] | null>(null);
  const { machineFilterSet } = useMultiFilters();

  const decodedMessage = useMemo(() => decodeUriString(message), [message]);

  const refreshHandler = () => {
    setData(null);
    loadMachinesByReasonData();
  };

  usePageTitle(
    useMemo<ITitleData>(() => {
      let result: ITitleData = {
        title: `${subject ? `${subject} ` : ""}${subject && decodedMessage ? "| " : ""}${decodedMessage ? ` ${decodedMessage}` : ""}`,
        subTitle: decodedMessage ? "Status Message" : "Endpoints by state of subject",
      };

      if (statusString && statusString !== "all") {
        result.title += ` | ${normalizeString(statusString)}`;
      }

      return result;
    }, [subject, statusString, decodedMessage])
  );

  useBreadcrumbs(
    useMemo<IAppBreadcrumb[]>(() => {
      let result: IAppBreadcrumb[] = [];

      if (time) {
        result.push({
          title: "Time",
          name: toFormatedDate(time),
          url: `/?selectedDate=${time}`,
        });
      }

      if (subject) {
        result.push({
          title: "Subject",
          name: subject,
          url: getSubjectByReasonPageUrl(subject, time),
        });
      }

      if (statusString && statusString !== "all") {
        result.push({
          title: "State",
          name: normalizeString(statusString),
        });
      }

      if (decodedMessage) {
        result.push({
          title: "Message",
          name: decodedMessage,
        });
      }

      return result;
    }, [time, subject, statusString, decodedMessage, getSubjectByReasonPageUrl])
  );

  const getMachinesByReasonData = useCallback(
    (noLimit?: boolean) => {
      const complianceStatus = statusToNumber(statusString ?? null);
      if ((complianceStatus === null && statusString !== "all") || !subject || !time || !machineFilterSet) {
        return;
      }

      return api.list.machinesByReason({
        complianceStatus: complianceStatus ?? 0,
        filters: machineFilterSet,
        subject,
        fromTime: new Date(time),
        packageMessage: decodedMessage ?? null,
        isUnknown: complianceStatus === 0,
        noLimit: noLimit === true,
      });
    },
    [api, statusString, subject, time, decodedMessage, machineFilterSet]
  );

  const exportDataHandler = useCallback(async () => {
    const csvDataToExport = await getMachinesByReasonData(true)
      ?.then((data: ListResponse<SubjectStatusAtTime> | null) => {
        return data?.isSuccess ? data.items : null;
      })
      .catch(() => null);

    return csvDataToExport?.map((x) => ({
      Endpoint: x.computerName,
      Package: x.packageName,
      State: ComplianceStatusToString(x.complianceStatus),
      Justification: x.complianceJustification,
      AssessmentTime: toFormatedDate(x.referenceJsTime!),
      AssessmentTimeISO8601: x.referenceJsTime,
      LastRecorded: toFormatedDate(x.lastReportedJsTime!),
      LastRecordedISO8601: x.lastReportedJsTime,
      UseCase: x.packageType,
      Rule: x.ruleDescription,
      LastExitCode: x.packageExitCode,
      StatusMessage: x.applicabilityMessage,
    }));
  }, [getMachinesByReasonData]);

  const loadMachinesByReasonData = useCallback(() => {
    getMachinesByReasonData()?.then((result) => {
      if (!result?.isSuccess) {
        console.warn(result?.failMessage);
        return;
      }

      setData(result.items);
    });
  }, [getMachinesByReasonData, setData]);

  useEffect(() => {
    loadMachinesByReasonData();
  }, [loadMachinesByReasonData]);

  const sortedData = useMemo<typeof data>(() => {
    if (data === null) {
      return null;
    }
    
    switch (sortField) {
      case "endpoint":
        return [...data].sort(getSorter("computerName", sortDesc));
      case "package":
        return [...data].sort(getSorter("packageName", sortDesc));
      case "state":
        return [...data].sort(getSorter("isUnknown", sortDesc)).sort(getSorter("complianceStatus", sortDesc));
      case "justification":
        return [...data].sort(getSorter("complianceJustification", sortDesc));
      case "time":
        return [...data].sort(getSorter("referenceJsTime", sortDesc));
      case "usecase":
        return [...data].sort(getSorter("packageType", sortDesc));
      case "rule":
        return [...data].sort(getSorter("ruleDescription", sortDesc));
      case "exitcode":
        return [...data].sort(getSorter("packageExitCode", sortDesc));
      case "message":
        return [...data].sort(getSorter("applicabilityMessage", sortDesc));
      default:
        return [...data];
    }
  }, [data, sortField, sortDesc]);

  const exportData = useMemo(
    () => ({
      fileName: `Aiden ${subject ? `${subject} ` : ""}${decodedMessage ? `${decodedMessage} ` : ""}${
        statusString && statusString !== "all" ? `${normalizeString(statusString)} ` : ""
      }${decodedMessage ? "Status Message " : "Endpoints by state of subject "}${toDate(time)}`,
      source: exportDataHandler,
    }),
    [exportDataHandler, decodedMessage, subject, time, statusString]
  );

  return sortedData != null ? (
    <>
      <ActionButtons onRefresh={refreshHandler}>{(decodedMessage ?? subject) && <ExportToCsvButton items={[exportData]} />}</ActionButtons>
      <EndpointFilter />
      <div className="machine-table scroll" style={{ maxHeight: "65vh" }}>
        <table className="table table-dialog sticky-table tb-endpoint">
          <thead className="sticky-top">
            <tr>
              <th className="fit align-middle" scope="col">
                <SortableTableHeader title="Endpoint" property="endpoint" style={{ whiteSpace: "nowrap" }} />
              </th>
              <th className="fit align-middle" scope="col">
                <SortableTableHeader
                  title="Package"
                  property="package"
                  style={{ whiteSpace: "nowrap", width: "100px", display: "block" }}
                />
              </th>
              <th className="fit align-middle" scope="col">
                <SortableTableHeader title="State" property="state" style={{ whiteSpace: "nowrap" }} />
              </th>
              <th className="fit align-middle" scope="col">
                <SortableTableHeader
                  title="Justification"
                  property="justification"
                  style={{ whiteSpace: "nowrap", width: "250px", display: "block" }}
                />
              </th>
              <th className="fit align-middle truncated-header" scope="col">
                <SortableTableHeader title="Assessment changed" property="time" />
              </th>
              <th className="fit align-middle truncated-header" scope="col">
                <SortableTableHeader title="Last recorded" property="time" style={{ whiteSpace: "nowrap", width: "120px", display: "block" }} />
              </th>
              <th className="fit align-middle truncated-header" scope="col">
                <SortableTableHeader title="Use case" property="usecase" />
              </th>
              <th className="fit align-middle" scope="col">
                <SortableTableHeader title="Rule" property="rule" style={{ whiteSpace: "nowrap", width: "100px", display: "block" }} />
              </th>
              <th className="fit align-middle truncated-header" scope="col">
                <SortableTableHeader title="Last exit code" property="exitcode" />
              </th>
              <th className="fit align-middle truncated-header" scope="col">
                <SortableTableHeader title="Status message" property="message" />
              </th>
            </tr>
          </thead>
          <tbody>
            {sortedData.map((x, index) => (
              <tr key={index}>
                <td>
                  <AppLink onClick={() => navigateToEndpointPage(x.machineId, time)}>{x.computerName}</AppLink>
                </td>
                <td>{x.packageName}</td>
                <td>
                  <ComplianceStatus code={x.complianceStatus} isUnknown={x.isUnknown} isSubject={true} />
                </td>
                <td>{x.complianceJustification}</td>
                <td>{toFormatedDate(x.referenceJsTime!)}</td>
                <td>{toFormatedDate(x.lastReportedJsTime!)}</td>
                <td>{x.packageType}</td>
                <td>{x.ruleDescription}</td>
                <td>{x.packageExitCode}</td>
                <td>{x.applicabilityMessage}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  ) : (
    <LoadingPage />
  );
};

export default EndpointsByReasonPage;
